<template>
  <div class="background">
    <div class="bankinfo-head"></div>
    <div class="bankinfo-ellipse"></div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.bankinfo-head {
  width: 100%;
  height: 140px;
  background: #13b5b1;
  position: absolute;
}
.bankinfo-ellipse {
  position: absolute;
  top: 140px;
  left: 0;
  width: 100%;
  height: 50px;
  margin: auto;
  background-color: #13b5b1;
  border-radius: 50% / 100% 100% 0 0;
  transform: rotateX(180deg);
}
</style>